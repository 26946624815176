<template>
  <div class="fileListPage">
    <h2>文件汇总</h2>
    <el-divider></el-divider>
    <el-tabs type="border-card">
      <el-tab-pane :label="panelItem.title" v-for="(panelItem,panelIndex) in list" :key="panelIndex">
          <a :href="item.href" class="itemFile" v-for="(item,index) in panelItem.list" :key="index">
              <div class="title">{{item.title}}</div>
              <div class="date">{{item.created_at}}</div>
          </a>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, watch, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
export default defineComponent({
  name: "fileList",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const list = [
        {
            title:'建设工程',
            list:setList([
                {title:'河南省住房和城乡建设厅关于延长建设工程企业资质有效期的通知豫建市〔2021〕341号',created_at:''},
                {title:'住房和城乡建设部关于开展房屋市政工程安全生产治理行动的通知',created_at:''},
                {title:'建设工程质量管理条例',created_at:''},
                {title:'建筑业企业资质管理规定和资质标准实施意见',created_at:''},
                {title:'河南建设工程质量管理条例',created_at:''},
                {title:'河南省工程质量手册（试行）',created_at:'2019-08-27'},
                {title:'河南省建筑市场管理条例-2005修订版',created_at:''},
                {title:'中华人民共和国住房和城乡建设部令(第5号)《房屋建筑和市政基础设施工程质量监督管理规定》',created_at:'2010-08-01'},
                {title:'河南省住房和城乡建设厅关于印发《河南省房屋建筑和市政基础设施工程质量监督管理实施办法》',created_at:'2012-03-15'},
            ],'product')
        },
        {
            title:'商混',
            list:setList([
                {title:'河南省住房和城乡建设厅关于开展全省预拌混凝土质量行为的通知',created_at:''},
                {title:'建质{2013}84号文件',created_at:'2013-05-24'},
                {title:'预拌混凝土专项承包资质标准',created_at:''},
                {title:'关于促进砂石行业健康有序发展的指导意见',created_at:'2020-03-25'},
                {title:'部0414+住房和城乡建设部办公厅关于2020年预拌混凝土质量专项抽查情况的通报[1]',created_at:'2020-12-25'},
                {title:'河南省预拌混凝土企业专项试验室基本条件-豫建{2018}12号文',created_at:'2018-02-07'},
                {title:'河南省预拌混凝土管理规定 豫建〔2018〕13号文',created_at:'2018-02-07'},
                {title:'河南-关于促进机制砂产业发展推广机制砂应用的指导意见（试行）',created_at:'2019-11-06'},
                {title:'关于河南省预拌混凝土生产企业质量保证体系动态评价试点工作的通知{2018}21号',created_at:'2018-09-14'},
                {title:'豫建质函【2020】9号 转发《河南省住房和城乡建设厅关于统计上报预拌混凝土生产企业相关信息数据的通知》',created_at:'2020-07-10'},
                {title:'豫建质〔2020〕8号-关于开展河南省预拌混凝土企业原材料试验能力比对的通知',created_at:'2020-09-25'},
                {title:'关于发布2020年度河南省预拌混凝土企业原材料试验能力比对结果的公告(豫建质【2021】2号)',created_at:'2021-01-29'},
            ],'busin')
        },
        {
            title:'检测',
            list:setList([
                {title:'关于2021年度郑州市工程质量检测机构监督抽查及检测标准化工作推进情况的通报',created_at:''},
                {title:'关于印发河南省房屋安全鉴定报告示范文本的通知',created_at:''},
                {title:'河南省住房和城乡建设厅 河南省市场监督管理局关于联合开展2022年全省建设工程质量检测专项治理的通知',created_at:''},
                {title:'河南省住房和城乡建设厅关于做好房屋安全鉴定管理有关工作的通知',created_at:''},
                {title:'豫建质〔2021〕22号关于2021年度河南省检测机构建筑节能能力比对结果的通报',created_at:''},
                {title:'建设工程质量检测管理办法（建设部第141号令）',created_at:'2005-09-28'},
                {title:'建筑协会信用评价办法（检测机构信用评价实施细则）',created_at:''},
                {title:'关于进一步加强建设工程质量检测管理的通知（豫建建{2016}66号）',created_at:'2016-09-22'},
                {title:'河南省建设工程质量检测管理办法的通知(豫建[2016]127号)',created_at:'2016-08-17'},
                {title:'房屋建筑工程和市政基础设施工程实行见证取样和送检的规定（建建{2000}211号）',created_at:'2000-09-26'},
                {title:'河南省住房和城乡建设厅《关于建设工程质量检测机构资质管理有关事项》的通知（建质安〔2019〕323号）',created_at:'2019-11-04'},
                {title:'河南省住房和城乡建设厅关于简化建设工程质量检测机构资质延期审批的通知（豫建质安（2020）109号）',created_at:'2020-03-27'},
                {title:'河南省住房和城乡建设厅关于印发全省建设工程质量检测机构专项治理活动方案的通知（豫建质安{2020}114号）',created_at:'2020-03-27'},
                {title:'河南省住房和城乡建设厅关于全省建设工程质量检测机构专项治理暨监督检查情况的通报（豫建质安{2020}285号）',created_at:'2020-08-12'},
                {title:'河南省住房和城乡建设厅关于规范全省建设工程质量检测机构管理的通知-豫建行规{2021}1号',created_at:'2021-03-25'},
                {title:'豫建质{2021}3号检测机构见证取样能力比对文件',created_at:'2020-03-24'},
                {title:'豫建质{2017}6号关于开展河南省建设工程质量检测机构、室内环境检测能力比对试验的通知',created_at:'2017-03-21'},
                {title:'关于2017年度河南省建设工程质量检测机构室内环境检测能力比对结果的通报（豫建建【2017】60号）',created_at:'2017-08-14'},
                {title:'关于开展河南省建设工程质量检测机构地基基础检测能力比对的通知（豫建质{2017}23号）',created_at:'2017-10-11'},
                {title:'河南省住房和城乡建设厅关于《2017年度河南省建设工程质量检测机构地基基础检测能力比对结果》的通报（豫建建〔2018〕}9号）',created_at:'2018-03-05'},
            ],'test')
        }
    ]

    function setList(list,type){
        let list1 = list.filter(item => item.created_at)
        let list2 = list.filter(item => !item.created_at)
        list1 = list1.sort((a, b) => {
          return new Date(b["created_at"]).valueOf() - new Date(a["created_at"]).valueOf()
        }).map(item => {
            return {
                ...item,
                href:`${process.env.VUE_APP_FILE_HOST}/file/file/${type}/${item.title}.pdf`
            }
        })
        list2 = list2.map(item => {
            return {
                ...item,
                href:`${process.env.VUE_APP_FILE_HOST}/file/file/${type}/${item.title}.pdf`
            }
        })
        return [...list1,...list2]
    }

    return {
        list
    };
  },
});
</script>
<style lang="less" scoped>
.fileListPage {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  .itemFile{
      text-align: left;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      color: black;
      cursor: pointer;
      &:not(:last-child){
          margin-bottom: 10px;
      }
      .date{
          color: #b2b2b2;
      }
      .title{
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }
      &:hover{
          .title{
              color:@mainColor;
              text-decoration: underline;
          }
      }
  }
}
@media (max-width: 600px) {
  .fileListPage {
    max-width: 100vw;
  }
}
</style>